import {
  GridColDef,
  GridPaginationModel,
  GridSortModel
} from '@mui/x-data-grid';
import oppurtuniyColumn from 'assets/list/dashboard/column';
import DefaultDashboardFields from 'assets/list/dashboard/default-column';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { ObjectType } from 'types';
import { DashboardFilterEntity } from 'types/dashboard-type';
import { OpportunityListEntity } from 'types/opportunity-types';
import defaultSort from 'assets/list/dashboard/default-sort';
import opportunityService from 'services/opportunity-service';
import filterService from 'services/filter-service';
import userPreferenceService from 'services/user-preference-service';
import fieldLabel from 'assets/constants/fieldLabel';
import eventBus from 'helpers/event-bus-helper';

import Filters from './filter';
import DataGrid, { DataGridProp } from 'components/data-grid';
import DASHBOARDS from 'assets/constants/dashboards';
import dashboardInitialFilters from 'state/dashboard';
import { InputChangeEvent } from 'types/common-types';
import { isEmpty } from 'helpers/misc-helper';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import ErrorComponent from 'components/errors/error-component';

interface DashboardComponentProps {
  dashboard: string;
  currentTab: number;
  updateCount: (val: ObjectType) => void;
}

const DashboardHOC = (DGComponent: (params: DataGridProp) => JSX.Element) => {
  function Component({
    dashboard,
    currentTab,
    updateCount
  }: DashboardComponentProps) {
    const [refreshColumn, setRefreshColumn] = useState<number>(0);
    const [refresh, setRefresh] = useState<number>(0);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [errorMessage, setErrorMessage] = useState<string>('');

    const [oppurtunities, setOppurtunities] = useState<OpportunityListEntity[]>(
      []
    );

    const [selectedColumns, setSelectedColumns] = useState<string[]>(
      DefaultDashboardFields.NEGOTIATOR_DASHBOARD_OFFER_MAKE_OFFER
    );

    const [rowCountState, setRowCountState] = React.useState(0);

    const [paginationModel, setPaginationModel] =
      React.useState<GridPaginationModel>({
        pageSize: 25,
        page: 0
      });

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
      defaultSort
    ]);

    const [filter, setFilter] = useState<DashboardFilterEntity>(
      dashboardInitialFilters[dashboard]
    );

    const [initialLoad, setInitialLoad] = useState<boolean>(false);

    const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);

    const getColumns = useCallback(() => {
      return oppurtuniyColumn.filter((x: GridColDef) =>
        selectedColumns.includes(x.field)
      );
    }, [selectedColumns]);

    const getOpportunities = async () => {
      setErrorMessage('');
      setIsFilterChanged(false);
      const request = prepareRequest();

      setIsLoading(true);
      const result = await opportunityService.getList(request);
      setIsLoading(false);
      if (result.isError) {
        setErrorMessage(result.errorMessage.message);
        return;
      }

      setOppurtunities(result.data.data);
      setRowCountState(result.data.meta.total);
      updateCount({
        [dashboard]: result.data.meta.total
      });
    };

    const getTableColumnsToShow = async () => {
      const response = await userPreferenceService.getItem<string[]>({
        category: dashboard,
        subcategory: DASHBOARDS.SELECTED_COLUMNS,
        module: fieldLabel.opportunities
      });

      if (response.isSuccess && response.data) {
        setSelectedColumns(response.data.contents);
      }
    };

    const prepareRequest = () => {
      let filterUrl = '';
      if (Object.keys(filter).length > 0) {
        filterUrl = filterService(filter, 'dashboard');
      }

      const queryString = listQueryString({
        pagination: paginationModel,
        sort: prepareSort(sortModel, defaultSort),
        filter: {}
      });

      return `${queryString}${filterUrl}`;
    };

    const updatePagiantion = (param: ObjectType) => {
      setPaginationModel(Object.assign({}, paginationModel, param));
    };

    const updateFilter = (e: InputChangeEvent) => {
      setFilter(Object.assign({}, filter, { [e.target.name]: e.target.value }));
    };

    const updateRefresh = () => setRefresh((prevRefresh) => prevRefresh + 1);

    const udpateRefreshColumn = () =>
      setRefreshColumn((prevRefreshColumn) => prevRefreshColumn + 1);

    useEffect(() => {
      if (!initialLoad) return;

      getOpportunities();
    }, [paginationModel.page]);

    useEffect(() => {
      if (!initialLoad) return;

      if (paginationModel.page === 0) {
        getOpportunities();
      } else {
        setPaginationModel(Object.assign({}, paginationModel, { page: 0 }));
      }
    }, [paginationModel.pageSize]);

    useEffect(() => {
      if (!initialLoad || sortModel.length == 0) return;

      if (paginationModel.page === 0) {
        getOpportunities();
      } else {
        setPaginationModel(Object.assign({}, paginationModel, { page: 0 }));
      }
    }, [JSON.stringify(sortModel)]);

    useEffect(() => {
      if (!isFilterChanged) return;

      if (initialLoad && currentTab !== 0) return;

      if (!initialLoad) {
        getTableColumnsToShow();
      }

      if (paginationModel.page === 0) {
        getOpportunities();
      } else {
        setPaginationModel(Object.assign({}, paginationModel, { page: 0 }));
      }

      setInitialLoad(true);
    }, [isFilterChanged]);

    useEffect(() => {
      if (!initialLoad) return;
      getOpportunities();
    }, [refresh]);

    useEffect(() => {
      if (!initialLoad) return;
      getTableColumnsToShow();
    }, [refreshColumn]);

    useEffect(() => {
      eventBus.on(`${dashboard}_refresh`, () => {
        updateRefresh();
      });

      eventBus.on(`${dashboard}_refresh_columns`, () => {
        udpateRefreshColumn();
      });
    }, []);

    if (!isEmpty(errorMessage))
      return (
        <PaperBox>
          <PaperBoxContent>
            <ErrorComponent message={errorMessage} />
          </PaperBoxContent>
        </PaperBox>
      );

    return (
      <Fragment>
        <Filters
          page={paginationModel.page}
          filter={filter}
          updateFilter={updateFilter}
          setIsFilterChanged={setIsFilterChanged}
          setFilter={setFilter}
          dashboard={dashboard}
          updatePagiantion={updatePagiantion}
        />

        <DGComponent
          rows={oppurtunities}
          columns={getColumns()}
          rowCount={rowCountState}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          loading={isLoading}
          error={errorMessage}
        />
      </Fragment>
    );
  }

  return Component;
};

export default DashboardHOC(DataGrid);
