import documentService from 'services/document-service';
import emailTemplateService from 'services/email-template-service';
import marketPreferenceBrokerageUserService from 'services/market-preference-brokerage-users-service';
import marketPreferenceService from 'services/market-preference-service';
import pdfTemplateService from 'services/pdf-template-service';
import opportunityService from 'services//opportunity-service';
import kbCategoryService from 'services/kb-category-service';
import sqsService from 'services/sqs-service';
import fieldLabel from './fieldLabel';
import opportunityBrokerageUserService from 'services/opportunity-brokerage-user-service';
import documentRevisionService from 'services/document-revision-service';
import msaService from 'services/msa-service';
import accountService from 'services/account-service';
import vendorService from 'services/vendor-service';
import partiesService from 'services/party-service';
import mailService from 'services/email-service';
import transactionHistoryService from 'services/transaction-history-service';
import tasksService from 'services/tasks-service';
import knowledgeBaseService from 'services/knowledge-base-service';
import brokerageTransactionRoleService from 'services/brokerage-transaction-role-service';
import federalHolidayCalendarService from 'services/federal-holiday-calendar-service';
import accountBuyerNameService from 'services/account-buyer-name-service';
import contractRevisionService from 'services/contract-revision-service';

const urlElements = {
  action: {
    'brokerage-action': {
      skipBreadcrumbs: true,
      label: fieldLabel.brokerageAction
    },
    edit: {
      label: fieldLabel.edit
    },
    create: {
      label: fieldLabel.create
    },
    view: {
      label: fieldLabel.view
    },
    terminate: {
      label: fieldLabel.terminate
    },
    'change-log': {
      label: fieldLabel.changeLog
    },
    'selection-list': {
      label: fieldLabel.select
    },
    upload: {
      label: fieldLabel.upload
    },
    list: {
      label: fieldLabel.list
    },
    notes_chats: {
      label: false,
      skipBreadcrumbs: true
    },
    negotiator_notes: {
      label: fieldLabel.negotiationNotes
    },
    transaction_notes: {
      label: fieldLabel.transactionNotes
    },
    brokerage_notes: {
      label: fieldLabel.brokerageNotes
    },
    client_chats: {
      label: fieldLabel.clientChat
    },
    generate_contract: {
      label: fieldLabel.generateContract
    },
    inbox: {
      label: fieldLabel.inbox
    },
    draft: {
      label: fieldLabel.draft
    },
    compose: {
      label: fieldLabel.compose
    },
    actions: {
      skipBreadcrumbs: true
    },
    cancel_opportunity: {
      label: fieldLabel.cancelOffer
    },
    reject_opportunity: {
      label: fieldLabel.rejectOffer
    },
    execute_contract: {
      label: fieldLabel.acceptOffer
    },
    opportunity_action_counter_offer: {
      label: fieldLabel.counterOffer
    },
    due_diligence_opportunity: {
      label: fieldLabel.enterDueDiligence
    },
    enter_sale_pending: {
      label: fieldLabel.enterSalePending
    },
    buyer_counter_offer_confirmation: {
      label: fieldLabel.buyerCounterOfferConfirmation
    },
    option_earnest_fee_status: {
      label: fieldLabel.optionEarnestFeeStatus
    },
    extension_request: {
      label: fieldLabel.extensionRequest
    },
    termination_request: {
      label: fieldLabel.terminationRequest
    },
    termination_approval: {
      label: fieldLabel.terminationComplete
    },
    retrade_approval: {
      label: fieldLabel.retradeComplete
    },
    request_price_adjustment: {
      label: fieldLabel.retradeRequest
    },
    clear_due_diligence: {
      label: fieldLabel.clearDueDiligence
    },
    due_diligence_fees: {
      label: fieldLabel.dueDiligenceFees
    },
    extension_confirmation: {
      label: fieldLabel.extensionConfirmation
    },
    negotiation_notes: {
      label: fieldLabel.negotiationNotes
    },
    closings: {
      label: fieldLabel.closing
    },
    'pdf-view': {
      label: fieldLabel.viewAsPdf
    },
    'readable-pdf-view': {
      label: fieldLabel.viewAsReadablePdf
    },
    dashboard: {
      label: fieldLabel.dashboard
    },
    negotiator: {
      label: fieldLabel.negotiator
    },
    closing: {
      label: fieldLabel.closing
    },
    transaction: {
      label: fieldLabel.transaction
    },
    roles: {
      label: fieldLabel.roles
    },
    viewer: {
      label: fieldLabel.viewer
    },
    duplicate: {
      label: fieldLabel.duplicate
    },
    'view-market-offer-defaults': {
      label: fieldLabel.viewMarketOfferDefaults
    },
    replace: {
      label: fieldLabel.replace
    },
    forward: {
      label: fieldLabel.forward
    },
    replyAll: {
      label: fieldLabel.replyAll
    },
    delete: {
      label: fieldLabel.delete
    },
    re_trigger_integration_message_offer_made: {
      label: fieldLabel.reTriggerIntegrationMessageOfferMade
    },
    re_trigger_integration_message_offer_accepted: {
      label: fieldLabel.reTriggerIntegrationMessageOfferAccepted
    },
    profile: {
      label: fieldLabel.profile
    }
  },

  'market-preferences': {
    list: {
      label: fieldLabel.marketPreference
    },
    entity: {
      service: marketPreferenceService
    }
  },
  'market-preferences-brokerage-users': {
    list: {
      label: fieldLabel.marketPreferencesBrokerageUser
    },
    entity: {
      service: marketPreferenceBrokerageUserService
    }
  },
  contracts: {
    list: {
      label: fieldLabel.contracts
    },
    entity: {
      service: pdfTemplateService
    }
  },
  'pdf-templates': {
    list: {
      label: fieldLabel.contracts
    },
    entity: {
      service: pdfTemplateService
    }
  },
  documents: {
    list: {
      label: fieldLabel.documents
    },
    entity: {
      service: documentService
    }
  },
  'email-templates': {
    list: {
      label: fieldLabel.emailTemplates
    },
    entity: {
      service: emailTemplateService
    }
  },
  'knowledge-base-categories': {
    list: {
      label: 'Knowledge Base Category'
    },
    entity: {
      service: kbCategoryService
    }
  },
  opportunities: {
    list: {
      label: fieldLabel.closing,
      skipBreadcrumbs: true
    },
    entity: {
      service: opportunityService
    }
  },
  sqs: {
    list: {
      label: fieldLabel.sqs
    },
    entity: {
      service: sqsService
    }
  },
  parties: {
    list: {
      label: fieldLabel.parties
    },
    entity: {
      service: partiesService
    }
  },
  calls: {
    list: {
      label: fieldLabel.calls
    },
    entity: {
      service: sqsService
    }
  },
  tasks: {
    list: {
      label: fieldLabel.tasks
    },
    entity: {
      service: tasksService
    }
  },
  'transaction-histories': {
    list: {
      label: fieldLabel.transactionHistories
    },
    entity: {
      service: transactionHistoryService
    }
  },
  services: {
    list: {
      label: fieldLabel.services
    },
    entity: {
      service: vendorService
    }
  },
  email: {
    list: {
      label: fieldLabel.email,
      skipBreadcrumbs: true
    },
    entity: {
      service: mailService
    }
  },
  'opportunity-brokerage-users': {
    list: {
      label: fieldLabel.opportunityBrokerageUsers
    },
    entity: {
      service: opportunityBrokerageUserService
    }
  },
  revisions: {
    list: {
      label: fieldLabel.revisions
    },
    entity: {
      service: documentRevisionService
    }
  },
  contract_revisions: {
    list: {
      label: fieldLabel.revisions
    },
    entity: {
      service: contractRevisionService
    }
  },
  accounts: {
    list: {
      label: fieldLabel.customerAccounts
    },
    entity: {
      service: accountService
    }
  },
  msa: {
    list: {
      label: fieldLabel.msa
    },
    entity: {
      service: msaService
    }
  },
  inbox: {
    list: {
      label: fieldLabel.inbox
    },
    entity: {
      service: mailService
    }
  },
  draft: {
    list: {
      label: fieldLabel.draft
    },
    entity: {
      service: mailService
    }
  },
  properties: {
    list: {
      label: fieldLabel.properties
    },
    entity: {
      service: opportunityService
    }
  },
  'knowledge-bases': {
    list: {
      label: fieldLabel.knowledgeBase
    },
    entity: {
      service: knowledgeBaseService
    }
  },
  'brokerage-transaction-roles': {
    list: {
      label: fieldLabel.brokerageTransactionRoles
    },
    entity: {
      service: brokerageTransactionRoleService
    }
  },
  'federal-holiday-calendars': {
    list: {
      label: fieldLabel.federalHolidayCalendars
    },
    entity: {
      service: federalHolidayCalendarService
    }
  },
  'account-buyer-names': {
    list: {
      label: fieldLabel.accountBuyerNames
    },
    entity: {
      service: accountBuyerNameService
    }
  }
};

export default urlElements;
